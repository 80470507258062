import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import {
  Form,
  Select,
  InputNumber,
  Switch,
  Button,
  Input,
  Row, Col,
  message
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import Uploader from '../../../components/uploader/index';
import API from '../../../utils/api';
import request from "../../../utils/request";
import queryString from "query-string";
import EditableTagGroup from '../../../components/editableTagGroup/index';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import CustomClassicEditor from 'ckeditor5-custom-build-ljq';
import MyCustomUploadAdapterPlugin from '../../../components/myUploadAdapter';

import './index.scss';


const { Editor: ClassicEditor } = CustomClassicEditor;

const defaultConfig = {

  toolbar: {
    items: [
      'sourceEditing',
      'heading',
      '|',
      'fontSize',
      'bold',
      '-',
      'alignment',
      'italic',
      'fontColor',
      'fontBackgroundColor',
      // 'highlight',
      // 'fontFamily',
      '|',
      // 'link',
      // 'bulletedList',
      // 'numberedList',
      'blockQuote',
      '|',
      'imageUpload',
      'resizeImage',
      // 'mediaEmbed',
      'undo',
      'redo',
      'underline',
      'strikethrough',
      'removeFormat',
      'horizontalLine',
      'specialCharacters',
      '|',
      'indent',
      'outdent',

    ]
  },
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
      { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
      { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' }
    ]
  },
  fontSize: {
    options: [15, 20, 25, 30, 40, 50]
  },
  fontColor: {
    colors: [
      '#fff',
      '#eee',
      '#ccc',
      '#999',
      '#666',
      '#333',
      '#000',
      '#FA5151',
      '#C87D2F',
      '#FFC300',
      '#91D300',
      '#95EC69',
      '#07C160',
      '#10AEFF',
      '#1485EE',
      '#6467F0',
      '#576B95',
    ],
    columns: 9,
  },
  fontBackgroundColor: {
    colors: [
      '#fff',
      '#eee',
      '#ccc',
      '#999',
      '#666',
      '#333',
      '#000',
      '#FA5151',
      '#C87D2F',
      '#FFC300',
      '#91D300',
      '#95EC69',
      '#07C160',
      '#10AEFF',
      '#1485EE',
      '#6467F0',
      '#576B95',
    ],
    columns: 9,
  },
  image: {
    resizeUnit: "%",
    resizeOptions: [{
      name: 'resizeImage:original',
      value: null,
      icon: 'original'
    },
    {
      name: 'resizeImage:50',
      value: '50',
      icon: 'small'
    },
    {
      name: 'resizeImage:50',
      value: '75',
      icon: 'medium'
    },
    {
      name: 'resizeImage:75',
      value: '100',
      icon: 'large'
    },
    ],
    toolbar: ['resizeImage:50', 'resizeImage:75', 'resizeImage:100', 'resizeImage:original'],
  },
  table: {
    contentToolbar: [
      'tableColumn',
      'tableRow',
      'mergeTableCells',
      'tableCellProperties',
      'tableProperties'
    ]
  },
  licenseKey: '',
  extraPlugins: [
    // 上传腾讯云的插件
    MyCustomUploadAdapterPlugin
  ],
  // htmlSupport: {
  //   allow: [
  //     // Enables <div>, <details>, and <summary> elements with all kind of attributes.
  //     {
  //       name: /^(div|section|p|span|details|summary)$/,
  //       styles: true,
  //       classes: true,
  //       attributes: true
  //     },

  //     // Extends the existing Paragraph and Heading features
  //     // with classes and data-* attributes.
  //     {
  //       name: /^(p|h[2-4])$/,
  //       classes: true,
  //       attributes: /^data-/
  //     },

  //     // Enables <span>s with any inline styles.
  //     {
  //       name: 'span',
  //       styles: true
  //     },

  //     // Enables <abbr>s with the title attribute.
  //     {
  //       name: 'abbr',
  //       attributes: ['title']
  //     }
  //   ]
  // },
  // This value must be kept in sync with the language defined in webpack.config.js.
  language: 'zh-cn'
};


const formItemLayout = {
  labelCol: {
    span: 2,
  },
  // wrapperCol: {
  //   span: 20,
  // },
};
const halfLayout = {
  labelCol: {
    span: 4,
  }
}

const ArticleEdit = () => {
  const [id, setId] = useState(null);
  const [htmlContent, setHtmlContent] = useState('');
  const [articleDetail, setArticleDetail] = useState({
    coverList: [],
    videoList: [],
  });
  const [categoryList, setCategoryList] = useState([]);
  const [surveyList, setSurveyList] = useState([]);

  const history = useHistory();
  const location = useLocation();

  const ueRef = useRef(null)

  const [config] = useState({
    initialFrameWidth: '100%',
    initialFrameHeight: 400
  })



  const [form] = Form.useForm();

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed?.id) {
      setId(parsed.id);

      const getDetail = async () => {
        const res = await request.get(API.article.detail, { params: { id: parsed.id } });
        if (res?.message) {
          return message.error(res?.message);
        }
        setArticleDetail(res);
        setHtmlContent(_.unescape(res.content || ''));
        form.setFieldsValue({ ...res })
      }

      getDetail();
    }
  }, [location, form]);

  useEffect(() => {
    getCategoryList();
    getSurveyList();
  }, []);

  const getCategoryList = async (values = {}) => {
    const params = { ...values };
    Object.keys(params).forEach(k => {
      if (!params[k] && params[k] !== 0) {
        delete params[k];
      }
    })
    const res = await request.get(API.category.list, { params });

    if (res?.message) {
      return message.error(res?.message);
    }
    console.log(res);
    setCategoryList((res?.results || []).map(k => ({ ...k, value: k.id, label: k.title })));
  };

  const getSurveyList = async (values = {}) => {
    const params = { ...values };
    const res = await request.get(API.survey.list, { params });

    if (res?.message) {
      return message.error(res?.message);
    }
    console.log(res);
    setSurveyList((res?.results || []).map(k => ({ ...k, value: k.id, label: k.title })));
  };


  const onFinish = async (values) => {
    const articleObj = {
      id,
      title: values.title,
      subTitle: values.subTitle,
      category: values.category,
      survey: values.survey,
      tags: values.tags,
      initViewCount: values.initViewCount,
      canComment: values.canComment,
      content: _.escape(htmlContent || ''),
      coverList: (articleDetail.coverList || []).map(({ uid, ...k }) => ({ ...k })),
      videoList: (articleDetail.videoList || []).map(({ uid, ...k }) => ({ ...k })),
      weight: values.weight || 1,
    }

    Object.keys(articleObj).forEach(k => {
      if (!articleObj[k] && articleObj[k] !== 0) {
        delete articleObj[k];
      }
    })

    const apiUrl = id ? API.article.update : API.article.create;
    const res = await request.post(apiUrl, articleObj);

    if (res?.message) {
      return message.error(res?.message);
    }
    message.success(id ? "保存成功" : '创建成功');
    history.push('/article/list');
  };

  return (
    <Form
      name="validate_other"
      {...formItemLayout}
      onFinish={onFinish}
      form={form}
    >
      <Form.Item
        {...formItemLayout}
        name="title"
        label="标题"
        rules={[
          {
            required: true,
            message: '文章标题不能为空',
          },
        ]}
      >
        <Input placeholder="请输入文章标题" />
      </Form.Item>

      <Row gutter={30}>
        <Col span={12}>
          <Form.Item
            {...halfLayout}
            name="subTitle"
            label="副标题"
          >
            <Input placeholder="请输入文章副标题" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            {...halfLayout}
            name="canComment"
            label="是否允许评论"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={30}>

        <Col span={12}>
          <Form.Item
            {...halfLayout}
            name="category"
            label="分类"
            hasFeedback
          >
            <Select placeholder="请选择文章分类" options={categoryList} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            {...halfLayout}
            name="tags"
            label="标签"
          >
            <EditableTagGroup
              tags={articleDetail.tags}
              onChange={v => setArticleDetail({
                ...articleDetail,
                tags: v
              })} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={30}>
        <Col span={12}>
          <Form.Item
            {...halfLayout}
            label="权重"
            name="weight"
          >
            <Input type="number" placeholder="排序用，权重越高排越全面，默认为1" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            {...halfLayout}
            name="initViewCount"
            label="初始阅读数"
          >
            <InputNumber min={0} max={10000} />
          </Form.Item>
        </Col>
      </Row>


      <Form.Item label="封面图">
        <Uploader
          fileList={articleDetail.coverList}
          onChange={v => setArticleDetail({
            ...articleDetail,
            coverList: v
          })}
        />
      </Form.Item>
      <Form.Item label="视频">
        <Uploader
          fileList={articleDetail.videoList}
          onChange={v => setArticleDetail({
            ...articleDetail,
            videoList: v
          })}
        />
      </Form.Item>



      <Form.Item label="正文">
        <CKEditor
          editor={ClassicEditor}
          config={defaultConfig}
          data={htmlContent}
          onReady={editor => {
            // console.log('Editor is ready to use!', editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            // console.log({ event, editor, data });
            setHtmlContent(data);
          }}
          onBlur={(event, editor) => {
            // console.log('Blur.', editor);
          }}
          onFocus={(event, editor) => {
            // console.log('Focus.', editor);
          }}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Button type="primary" htmlType="submit">
          提交
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ArticleEdit;
