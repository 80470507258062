import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Form,
  Button,
  Input,
  message,
  Select
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import 'braft-editor/dist/index.css';
import API from '../../../utils/api';
import request from "../../../utils/request";
import queryString from "query-string";

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};


const ArticleCollectionEdit = () => {
  const [id, setId] = useState(null);
  const [articleList, setArticleList] = useState([]);
  const history = useHistory();
  const location = useLocation();

  const [form] = Form.useForm();

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed?.id) {
      setId(parsed.id);

      const getDetail = async () => {
        const res = await request.get(API.articleCollection.detail, { params: { id: parsed.id } });
        if (res?.message) {
          return message.error(res?.message);
        }
        form.setFieldsValue({ ...res })
      }

      getDetail();
    }
  }, [location, form]);

  useEffect(() => {
    onSearchArticleByTitle();
  }, [])

  const onFinish = async (values) => {
    const articleCollectionObj = {
      id,
      title: values.title,
      desc: values.desc,
      articleList: values.articleList,
    }

    Object.keys(articleCollectionObj).forEach(k => {
      if (!articleCollectionObj[k] && articleCollectionObj[k] !== 0) {
        delete articleCollectionObj[k];
      }
    })

    const apiUrl = id ? API.articleCollection.update : API.articleCollection.create;
    const res = await request.post(apiUrl, articleCollectionObj);

    if (res?.message) {
      return message.error(res?.message);
    }
    message.success(id ? "保存成功" : '创建成功');
    history.push('/articleCollection/list');
  };

  const onSearchArticleByTitle = async (keyword) => {
    const params = { limit: 100, };
    if (keyword) {
      params.title = keyword;
    }
    const res = await request.get(API.article.list, { params });
    if (res?.message) {
      return message.error(res?.message);
    }
    setArticleList((res?.results || []).map(k => ({ ...k, value: k.id, label: k.title })));
  }

  return (
    <Form
      name="validate_other"
      {...formItemLayout}
      onFinish={onFinish}
      form={form}
    >
      <Form.Item
        {...formItemLayout}
        name="title"
        label="合集名称"
        rules={[
          {
            required: true,
            message: '合集名称不能为空',
          },
        ]}
      >
        <Input placeholder="请输入合集名称" />
      </Form.Item>

      <Form.Item label="描述" name="desc" >
        <Input.TextArea />
      </Form.Item>

      <Form.Item label="文章列表" name="articleList" >
        <Select
          showSearch
          mode="multiple"
          // style={{ width: 200 }}
          placeholder="选择文章并添加到列表"
          options={articleList}
          filterOption={false}
          onSearch={onSearchArticleByTitle}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Button type="primary" htmlType="submit">
          提交
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ArticleCollectionEdit;
