import React from 'react';
import { Button } from 'antd';
import userUtil from '../../utils/user';

function Dashboard() {
  const user = userUtil.get();
  return (
    <div>
      <h2>你好 {user.name} !</h2>
      <div>
        <div>欢迎登录 <b>老年人手机课</b> 管理中心</div>
        <div>所有功能模块均从左侧导航栏进入</div>

      </div>
    </div>
  );
}

export default Dashboard;