import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Form,
  Button,
  Input,
  message,
  Select
} from 'antd';
import BraftEditor from 'braft-editor';
import { useHistory, useLocation } from 'react-router-dom';
import 'braft-editor/dist/index.css';
import Uploader from '../../../components/uploader/index';
import API from '../../../utils/api';
import request from "../../../utils/request";
import queryString from "query-string";

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};


const BannerEdit = () => {
  const [id, setId] = useState(null);
  const [bannerDetail, setBannerDetail] = useState({});
  const [jumpTargetOptions, setJumpTargetOptions] = useState([]);

  const history = useHistory();
  const location = useLocation();


  const [form] = Form.useForm();

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed?.id) {
      setId(parsed.id);

      const getDetail = async () => {
        const res = await request.get(API.banner.detail, { params: { id: parsed.id } });
        if (res?.message) {
          return message.error(res?.message);
        }
        setBannerDetail(res);
        form.setFieldsValue({ ...res })
        onSearchJumpTarget();
      }

      getDetail();
    } else {
      onSearchJumpTarget();
    }
  }, [location, form]);

  const onFinish = async (values) => {
    const bannerObj = {
      id,
      title: values.title,
      desc: values.desc,
      image: bannerDetail.image,
      jumpType: values.jumpType,
      jumpTarget: values.jumpTarget,
      weight: values.weight,
    }

    Object.keys(bannerObj).forEach(k => {
      if (!bannerObj[k] && bannerObj[k] !== 0) {
        delete bannerObj[k];
      }
    })

    const apiUrl = id ? API.banner.update : API.banner.create;
    const res = await request.post(apiUrl, bannerObj);

    if (res?.message) {
      return message.error(res?.message);
    }
    message.success(id ? "保存成功" : '创建成功');
    history.push('/banner/list');
  };

  const onJumpTypeChange = (v) => {
    form.setFieldsValue({
      jumpType: v,
      jumpTarget: null,
    });
    onSearchJumpTarget();
  }

  const onSearchJumpTarget = async (keyword) => {
    const jumpType = form.getFieldValue('jumpType') || 'article';
    const apiUrl = API[jumpType]?.list;
    const params = { limit: 100, };
    if (keyword) {
      params.title = keyword;
    }
    const res = await request.get(apiUrl, { params });
    if (res?.message) {
      return message.error(res?.message);
    }
    setJumpTargetOptions((res?.results || []).map(k => ({ ...k, value: k.id, label: k.title })));
  }

  return (
    <Form
      name="validate_other"
      {...formItemLayout}
      onFinish={onFinish}
      form={form}
      initialValues={{ jumpType: 'article' }}
    >
      <Form.Item
        {...formItemLayout}
        name="title"
        label="标题"
        rules={[
          {
            required: true,
            message: 'Banner标题不能为空',
          },
        ]}
      >
        <Input placeholder="请输入Banner标题" />
      </Form.Item>

      <Form.Item label="封面图">
        <Uploader
          fileList={bannerDetail?.image ? [bannerDetail.image] : []}
          onChange={v => setBannerDetail({
            ...bannerDetail,
            image: v?.[0]
          })}
        />
      </Form.Item>

      <Form.Item label="描述" name="desc" >
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="跳转类型" name="jumpType" >
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="选择跳转类型"
          optionFilterProp="children"
          onChange={onJumpTypeChange}
        >
          <Select.Option value="article">文章</Select.Option>
          <Select.Option value="articleCollection">文章合集</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="跳转目标" name="jumpTarget" >
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="选择跳转目标"
          onSearch={onSearchJumpTarget}
          options={jumpTargetOptions}
        >
          <Select.Option value="article">文章</Select.Option>
          <Select.Option value="articleCollection">文章合集</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label="权重" name="weight" >
        <Input type="number" placeholder="排序用，权重越高排越全面，默认为1" />
      </Form.Item>


      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Button type="primary" htmlType="submit">
          提交
        </Button>
      </Form.Item>
    </Form>
  );
};

export default BannerEdit;
