import axios from 'axios';
import Cookies from 'js-cookie';
import { message } from 'antd';
import userUtil from './user';

const getBaseUrl = (env) => {
  let base = {
    production: '/api',
    // development: 'https://af.os.lxx-inc.com/api',
    development: 'http://127.0.0.1:9001/v1',
  }[env];
  if (!base) {
    base = '/api';
  }
  return base;
};

axios.defaults.baseURL = getBaseUrl(process.env.NODE_ENV);

if (Cookies.get('access_token')) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('access_token');
}
axios.defaults.withCredentials = true;


axios.interceptors.request.use(config => {
  // loading
  return config
}, error => {
  return Promise.reject(error)
})

axios.interceptors.response.use(response => {
  const { status, data } = response || {};
  return data || response;
}, error => {
  if (error?.response?.status === 401) {
    userUtil.set('');
    window.location.href = '/login?callbackUrl=' + encodeURIComponent(window.location.href);
  } else {
    return Promise.resolve(error.response && error.response.data)
  }
})

export default axios;