
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Space, Divider, Form, Row, Col, Input, Button, message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import API from '../../../utils/api';
import request from "../../../utils/request";


const AdvancedSearchForm = (props) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    props.onSearch(values);
  };

  return (
    <Form
      form={form}
      name="advanced_search"
      className="ant-advanced-search-form"
      onFinish={onFinish}
    >
      <Row gutter={24}>
        <Col span={8} >
          <Form.Item name='title' label="调研问卷标题">
            <Input placeholder="请输入要搜索的调研问卷标题" />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
          <Button style={{ margin: '0 8px' }} onClick={() => form.resetFields()}>
            Clear
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const SurveyList = () => {
  const history = useHistory();

  const [list, setList] = useState([]);
  const [searchParams, setSearchParams] = useState({});
  const [pageInfo, setPageInfo] = useState({ current: 1, pageSize: 10, total: 0 });
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);


  useEffect(() => {
    getList({
      ...searchParams,
      page: current,
      limit: pageSize,
    });
  }, [searchParams, current, pageSize]);

  const onSearch = (params) => {
    setSearchParams(params);
    setCurrent(1);
  }

  const getList = async (params) => {
    Object.keys(params).forEach(k => {
      if (!params[k] && params[k] !== 0) {
        delete params[k];
      }
    })
    const res = await request.get(API.survey.list, { params });

    if (res?.message) {
      return message.error(res?.message);
    }
    setList(res?.results || []);
    setTotal(res?.totalResults);
  };

  const onClickDelete = (record) => {
    Modal.confirm({
      title: '请确认！',
      icon: <ExclamationCircleOutlined />,
      content: `确认要删除调研问卷《${record.title}》吗？删除之后不可恢复！`,
      okText: '删除',
      okType: 'danger',
      cancelText: '取消',
      onOk: async () => {
        const res = await request.post(API.survey.delete, { id: record.id });

        if (res?.message) {
          return message.error(res?.message);
        }

        message.success('删除成功');
        getList();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  return (
    <div>

      <Row style={{ marginBottom: 20 }}>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" onClick={() => history.push('/survey/edit')}>
            新增调研问卷
          </Button>
        </Col>
      </Row>


      <AdvancedSearchForm onSearch={onSearch} />

      <Divider />

      <Table
        pagination={{
          current,
          pageSize,
          total,
          onChange: setCurrent,
          onShowSizeChange: setPageSize
        }}
        dataSource={list || []}
      >
        <Table.Column title="标题" dataIndex="title" />
        <Table.Column title="描述" dataIndex="desc" />
        <Table.Column title="操作" width={170} render={(value, record) => {
          return (
            <Space>
              <Button type="link" size="small">查看</Button>
              <Button type="link" size="small" onClick={() => history.push({
                pathname: '/survey/edit',
                search: `?id=${record.id}`
              })}>编辑</Button>
              <Button type="link" size="small" onClick={() => onClickDelete(record)}>删除</Button>
            </Space>
          )
        }} />
      </Table>
    </div>)
}

export default SurveyList;