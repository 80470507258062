import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import {
  Form,
  Select,
  InputNumber,
  Switch,
  Button,
  Input,
  message
} from 'antd';
import BraftEditor from 'braft-editor';
import { useHistory, useLocation } from 'react-router-dom';
import 'braft-editor/dist/index.css';
import Uploader from '../../../components/uploader/index';
import API from '../../../utils/api';
import request from "../../../utils/request";
import queryString from "query-string";
import RichTextEditor from '../../../components/richTextEditor/index';
import EditableTagGroup from '../../../components/editableTagGroup/index';
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};


const ArticleEdit = () => {
  const [id, setId] = useState(null);
  const [editorState, setEditorState] = useState(null);
  const [articleDetail, setArticleDetail] = useState({
    coverList: [],
    videoList: [],
  });
  const [categoryList, setCategoryList] = useState([]);

  const history = useHistory();
  const location = useLocation();


  const [form] = Form.useForm();

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed?.id) {
      setId(parsed.id);

      const getDetail = async () => {
        const res = await request.get(API.article.detail, { params: { id: parsed.id } });
        if (res?.message) {
          return message.error(res?.message);
        }
        setArticleDetail(res);
        setEditorState(BraftEditor.createEditorState(_.unescape(res.content || '')))
        form.setFieldsValue({ ...res })
      }

      getDetail();
    }
  }, [location, form]);

  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = async (values = {}) => {
    const params = { ...values };
    Object.keys(params).forEach(k => {
      if (!params[k] && params[k] !== 0) {
        delete params[k];
      }
    })
    const res = await request.get(API.category.list, { params });

    if (res?.message) {
      return message.error(res?.message);
    }
    console.log(res);
    setCategoryList((res?.results || []).map(k => ({ ...k, value: k.id, label: k.title })));
  };


  const onFinish = async (values) => {
    const htmlContent = editorState ? editorState.toHTML() : '';
    const articleObj = {
      id,
      title: values.title,
      subTitle: values.subTitle,
      category: values.category,
      tags: values.tags,
      initViewCount: values.initViewCount,
      canComment: values.canComment,
      content: _.escape(htmlContent || ''),
      coverList: (articleDetail.coverList || []).map(({ uid, ...k }) => ({ ...k })),
      videoList: (articleDetail.videoList || []).map(({ uid, ...k }) => ({ ...k })),
    }

    Object.keys(articleObj).forEach(k => {
      if (!articleObj[k] && articleObj[k] !== 0) {
        delete articleObj[k];
      }
    })

    const apiUrl = id ? API.article.update : API.article.create;
    const res = await request.post(apiUrl, articleObj);

    if (res?.message) {
      return message.error(res?.message);
    }
    message.success(id ? "保存成功" : '创建成功');
    history.push('/article/list');
  };

  return (
    <Form
      name="validate_other"
      {...formItemLayout}
      onFinish={onFinish}
      form={form}
    >
      <Form.Item
        {...formItemLayout}
        name="title"
        label="标题"
        rules={[
          {
            required: true,
            message: '文章标题不能为空',
          },
        ]}
      >
        <Input placeholder="请输入文章标题" />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="subTitle"
        label="副标题"
      >
        <Input placeholder="请输入文章副标题" />
      </Form.Item>
      <Form.Item
        name="category"
        label="分类"
        hasFeedback
      >
        <Select placeholder="请选择文章分类" options={categoryList} />
      </Form.Item>

      <Form.Item name="tags" label="标签">
        <EditableTagGroup
          tags={articleDetail.tags}
          onChange={v => setArticleDetail({
            ...articleDetail,
            tags: v
          })} />
      </Form.Item>

      <Form.Item name="initViewCount" label="初始阅读数">
        <InputNumber min={0} max={10000} />
      </Form.Item>

      <Form.Item name="canComment" label="是否允许评论" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item label="封面图">
        <Uploader
          fileList={articleDetail.coverList}
          onChange={v => setArticleDetail({
            ...articleDetail,
            coverList: v
          })}
        />
      </Form.Item>
      <Form.Item label="视频">
        <Uploader
          fileList={articleDetail.videoList}
          onChange={v => setArticleDetail({
            ...articleDetail,
            videoList: v
          })}
        />
      </Form.Item>

      <Form.Item label="正文">

        <RichTextEditor
          editorState={editorState}
          onChange={setEditorState}
        ></RichTextEditor>
      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Button type="primary" htmlType="submit">
          提交
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ArticleEdit;
