import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Form,
  Button,
  Input,
  message,
  Select
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import 'braft-editor/dist/index.css';
import Uploader from '../../../components/uploader/index';
import API from '../../../utils/api';
import request from "../../../utils/request";
import queryString from "query-string";

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};


const WxGroupEdit = () => {
  const [id, setId] = useState(null);
  const [wxGroupDetail, setWxGroupDetail] = useState({});

  const history = useHistory();
  const location = useLocation();


  const [form] = Form.useForm();

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed?.id) {
      setId(parsed.id);

      const getDetail = async () => {
        const res = await request.get(API.wxGroup.detail, { params: { id: parsed.id } });
        if (res?.message) {
          return message.error(res?.message);
        }
        setWxGroupDetail(res);
        form.setFieldsValue({ ...res })
      }

      getDetail();
    }
  }, [location, form]);

  const onFinish = async (values) => {
    const wxGroupObj = {
      id,
      title: values.title,
      desc: values.desc,
      cover: wxGroupDetail.cover,
      qrCode: wxGroupDetail.qrCode,
    }

    Object.keys(wxGroupObj).forEach(k => {
      if (!wxGroupObj[k] && wxGroupObj[k] !== 0) {
        delete wxGroupObj[k];
      }
    })

    const apiUrl = id ? API.wxGroup.update : API.wxGroup.create;
    const res = await request.post(apiUrl, wxGroupObj);

    if (res?.message) {
      return message.error(res?.message);
    }
    message.success(id ? "保存成功" : '创建成功');
    history.push('/wxGroup/list');
  };

  return (
    <Form
      name="validate_other"
      {...formItemLayout}
      onFinish={onFinish}
      form={form}
      initialValues={{ jumpType: 'article' }}
    >
      <Form.Item
        {...formItemLayout}
        name="title"
        label="标题"
        rules={[
          {
            required: true,
            message: '微信群标题不能为空',
          },
        ]}
      >
        <Input placeholder="请输入微信群标题" />
      </Form.Item>

      <Form.Item label="封面图">
        <Uploader
          fileList={wxGroupDetail?.cover ? [wxGroupDetail.cover] : []}
          onChange={v => setWxGroupDetail({
            ...wxGroupDetail,
            cover: v?.[0]
          })}
        />
      </Form.Item>

      <Form.Item label="二维码">
        <Uploader
          fileList={wxGroupDetail?.qrCode ? [wxGroupDetail.qrCode] : []}
          onChange={v => setWxGroupDetail({
            ...wxGroupDetail,
            qrCode: v?.[0]
          })}
        />
      </Form.Item>

      <Form.Item label="描述" name="desc" >
        <Input.TextArea />
      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Button type="primary" htmlType="submit">
          提交
        </Button>
      </Form.Item>
    </Form>
  );
};

export default WxGroupEdit;
