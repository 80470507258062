
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Space, Divider, Form, Row, Col, Input, Button, message, Modal, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import API from '../../../utils/api';
import request from "../../../utils/request";


const AdvancedSearchForm = (props) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    props.onSearch(values);
  };

  return (
    <Form
      form={form}
      name="advanced_search"
      className="ant-advanced-search-form"
      onFinish={onFinish}
    >
      <Row gutter={24}>
        <Col span={8} >
          <Form.Item name='title' label="文章标题">
            <Input placeholder="请输入要搜索的文章标题" />
          </Form.Item>
        </Col>
        <Col span={8} >
          <Form.Item
            name="category"
            label="分类"
            hasFeedback
          >
            <Select placeholder="请选择文章分类" options={props.categoryList || []} />
          </Form.Item>
        </Col>

      </Row>

      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
          <Button style={{ margin: '0 8px' }} onClick={() => form.resetFields()}>
            Clear
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const ArticleList = () => {
  const history = useHistory();

  const [list, setList] = useState([]);
  const [searchParams, setSearchParams] = useState({});
  const [pageInfo, setPageInfo] = useState({ current: 1, pageSize: 10, total: 0 });
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);

  const [categoryList, setCategoryList] = useState([]);


  useEffect(() => {
    getCategoryList()
  }, []);

  useEffect(() => {
    getList({
      ...searchParams,
      page: current,
      limit: pageSize,
    });
  }, [searchParams, current, pageSize]);

  const onSearch = (params) => {
    setSearchParams(params);
    setCurrent(1);
  }

  const getCategoryList = async (values = {}) => {
    const params = { ...values };
    Object.keys(params).forEach(k => {
      if (!params[k] && params[k] !== 0) {
        delete params[k];
      }
    })
    const res = await request.get(API.category.list, { params });

    if (res?.message) {
      return message.error(res?.message);
    }
    setCategoryList((res?.results || []).map(k => ({ ...k, value: k.id, label: k.title })));
  };


  const getList = async (params) => {
    Object.keys(params || {}).forEach(k => {
      if (!params[k] && params[k] !== 0) {
        delete params[k];
      }
    })
    const res = await request.get(API.article.list, { params });

    if (res?.message) {
      return message.error(res?.message);
    }
    setList(res?.results || []);
    setTotal(res?.totalResults);
  };

  const onClickDelete = (record) => {
    Modal.confirm({
      title: '请确认！',
      icon: <ExclamationCircleOutlined />,
      content: `确认要删除文章《${record.title}》吗？删除之后不可恢复！`,
      okText: '删除',
      okType: 'danger',
      cancelText: '取消',
      onOk: async () => {
        const res = await request.post(API.article.delete, { id: record.id });

        if (res?.message) {
          return message.error(res?.message);
        }

        message.success('删除成功');
        getList();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  return (
    <div>

      <Row style={{ marginBottom: 20 }}>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" onClick={() => history.push('/article/edit')}>
            新增文章
          </Button>
        </Col>
      </Row>


      <AdvancedSearchForm onSearch={onSearch} categoryList={categoryList} />

      <Divider />

      <Table
        pagination={{
          current,
          pageSize,
          total,
          onChange: setCurrent,
          onShowSizeChange: setPageSize
        }}
        dataSource={list || []}
      >
        <Table.Column title="标题" dataIndex="title" />
        <Table.Column title="作者" dataIndex={['author', 'name']} />
        <Table.Column title="所属分类" dataIndex="category" render={v => (categoryList || []).find(k => k.value === v)?.label} />
        {/* <Table.Column title="标签" dataIndex="tags" render={tags => (tags || []).join('，')} /> */}
        <Table.Column title="创建时间" dataIndex="createdAt" render={v => dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />
        <Table.Column title="最后修改时间" dataIndex="updatedAt" render={v => dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />
        <Table.Column title="操作" width={170} render={(value, record) => {
          return (
            <Space>
              <Button type="link" size="small" onClick={() => history.push({
                pathname: '/article/detail',
                search: `?id=${record.id}`
              })}>查看</Button>
              <Button type="link" size="small" onClick={() => history.push({
                pathname: '/article/edit',
                search: `?id=${record.id}`
              })}>编辑</Button>
              <Button type="link" size="small" onClick={() => onClickDelete(record)}>删除</Button>
            </Space>
          )
        }} />
      </Table>
    </div>)
}

export default ArticleList;