import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Form,
  Button,
  Input,
  message,
  Select
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import 'braft-editor/dist/index.css';
import Uploader from '../../../components/uploader/index';
import API from '../../../utils/api';
import request from "../../../utils/request";
import queryString from "query-string";

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};


const CategoryEdit = () => {
  const [id, setId] = useState(null);
  const [categoryDetail, setCategoryDetail] = useState({});
  const [categoryList, setCategoryList] = useState([]);

  const history = useHistory();
  const location = useLocation();


  const [form] = Form.useForm();

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed?.id) {
      setId(parsed.id);

      const getDetail = async () => {
        const res = await request.get(API.category.detail, { params: { id: parsed.id } });
        if (res?.message) {
          return message.error(res?.message);
        }
        setCategoryDetail(res);
        form.setFieldsValue({ ...res })
      }

      getDetail();
    }
  }, [location, form]);

  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = async (values = {}) => {
    const params = { ...values };
    Object.keys(params).forEach(k => {
      if (!params[k] && params[k] !== 0) {
        delete params[k];
      }
    })
    const res = await request.get(API.category.list, { params });

    if (res?.message) {
      return message.error(res?.message);
    }
    console.log(res);
    setCategoryList((res?.results || []).map(k => ({ ...k, value: k.id, label: k.title })));
  };

  const onFinish = async (values) => {
    const categoryObj = {
      id,
      title: values.title,
      parentCategory: values.parentCategory,
      cover: categoryDetail.cover,
      desc: values.desc,
      weight: values.weight,
    }

    Object.keys(categoryObj).forEach(k => {
      if (!categoryObj[k] && categoryObj[k] !== 0) {
        delete categoryObj[k];
      }
    })

    const apiUrl = id ? API.category.update : API.category.create;
    const res = await request.post(apiUrl, categoryObj);

    if (res?.message) {
      return message.error(res?.message);
    }
    message.success(id ? "保存成功" : '创建成功');
    history.push('/category/list');
  };

  return (
    <Form
      name="validate_other"
      {...formItemLayout}
      onFinish={onFinish}
      form={form}
      initialValues={{}}
    >
      <Form.Item
        {...formItemLayout}
        name="title"
        label="分类名称"
        rules={[
          {
            required: true,
            message: '分类名称不能为空',
          },
        ]}
      >
        <Input placeholder="请输入分类名称" />
      </Form.Item>

      <Form.Item
        name="parentCategory"
        label="父级分类"
        hasFeedback
      >
        <Select placeholder="请选择父级分类" options={categoryList} />
      </Form.Item>

      <Form.Item label="封面图">
        <Uploader
          fileList={categoryDetail?.cover ? [categoryDetail.cover] : []}
          onChange={v => setCategoryDetail({
            ...categoryDetail,
            cover: v?.[0]
          })}
        />
      </Form.Item>

      <Form.Item label="描述" name="desc" >
        <Input.TextArea />
      </Form.Item>

      <Form.Item label="权重" name="weight" >
        <Input type="number" placeholder="排序用，权重越高排越全面，默认为1" />
      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Button type="primary" htmlType="submit">
          提交
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CategoryEdit;
