
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Tag, Space, Divider, Form, Row, Col, Input, Button, message, Modal, Image, Select } from 'antd';
import { DownOutlined, UpOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import API from '../../../utils/api';
import request from "../../../utils/request";
import dayjs from 'dayjs';

const genderMap = { '1': '男', '2': '女', '0': '未知' };

const AdvancedSearchForm = (props) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    props.onSearch(values);
  };

  return (
    <Form
      form={form}
      name="advanced_search"
      className="ant-advanced-search-form"
      onFinish={onFinish}
    >
      <Row gutter={24}>
        <Col span={8} >
          <Form.Item name='name' label="用户名称">
            <Input placeholder="请输入要搜索的用户名称" />
          </Form.Item>
        </Col>
        <Col span={8} >
          <Form.Item name='gender' label="性别">
            <Select
              placeholder="请选择用户性别"
              options={(Object.keys(genderMap).map(k => ({ label: genderMap[k], value: k })))}
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
          <Button style={{ margin: '0 8px' }} onClick={() => form.resetFields()}>
            Clear
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const UserList = () => {
  const history = useHistory();

  const [list, setList] = useState([]);
  const [searchParams, setSearchParams] = useState({});
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);



  useEffect(() => {
    getList({
      ...searchParams,
      page: current,
      limit: pageSize,
    });
  }, [searchParams, current, pageSize]);

  const onSearch = (params) => {
    setSearchParams(params);
    setCurrent(1);
  }

  const getList = async (values = {}) => {
    const params = { ...values };
    Object.keys(params).forEach(k => {
      if (!params[k] && params[k] !== 0) {
        delete params[k];
      }
    })
    const res = await request.get(API.user.list, { params });

    if (res?.message) {
      return message.error(res?.message);
    }
    setList(res?.results || []);
    setTotal(res?.totalResults);
  };

  const onClickDelete = (record) => {
    Modal.confirm({
      title: '请确认！',
      icon: <ExclamationCircleOutlined />,
      content: `确认要删除用户《${record.name}》吗？删除之后不可恢复！`,
      okText: '删除',
      okType: 'danger',
      cancelText: '取消',
      onOk: async () => {
        const res = await request.post(API.user.delete, { id: record.id });

        if (res?.message) {
          return message.error(res?.message);
        }

        message.success('删除成功');
        getList();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  return (
    <div>

      {

        // <Row style={{ marginBottom: 20 }}>
        //   <Col span={24} style={{ textAlign: 'right' }}>
        //     <Button type="primary" onClick={() => history.push('/user/edit')}>
        //       新增用户
        //     </Button>
        //   </Col>
        // </Row>
      }


      <AdvancedSearchForm onSearch={onSearch} />

      <Divider />

      <Table
        pagination={{
          current,
          pageSize,
          total,
          onChange: setCurrent,
          onShowSizeChange: setPageSize
        }}
        dataSource={list || []}
      >
        <Table.Column title="用户名" dataIndex="name" />
        <Table.Column title="头像" dataIndex="avatar" render={v => <Image src={v} width={50} />} />
        <Table.Column title="性别" dataIndex="gender" render={v => genderMap[v] || '未知'} />
        <Table.Column title="注册时间" dataIndex="createdAt" render={v => dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />
        <Table.Column title="操作" width={170} render={(value, record) => {
          return (
            <Space>
              {

                // <Button type="link" size="small">查看</Button>
              }
              {
                //  <Button type="link" size="small" onClick={() => history.push({
                //   pathname: '/user/edit',
                //   search: `?id=${record.id}`
                // })}>编辑</Button>
              }

              <Button type="link" size="small" onClick={() => onClickDelete(record)}>删除</Button>
            </Space>
          )
        }} />
      </Table>
    </div>)
}

export default UserList;