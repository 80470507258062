import React from "react";
import ArticleList from './list/index';
import ArticleEdit from './edit/index';
import ArticleDetail from './detail/index';
import { Route } from "react-router-dom";

export default function User() {
  return (
    <Route
      path="/user"
      render={({ match: { url } }) => (
        <React.Fragment>
          <Route path={`${url}/`} component={ArticleList} exact />
          <Route path={`${url}/list`} component={ArticleList} />
          <Route path={`${url}/detail`} component={ArticleDetail} />
          <Route path={`${url}/edit`} component={ArticleEdit} />
        </React.Fragment>
      )}
    />
  )
}