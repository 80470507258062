import dayjs from 'dayjs';
import cosUploader from '../../utils/cosUploader';

class MyUploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file
      .then(file => new Promise((resolve, reject) => {
        console.log('上传图片：', file);
        const filename = dayjs().format('YYYY-MM-DD_HH-mm-ss') + '_' + file.name;
        cosUploader.uploadFile({
          key: filename,
          body: file,
          ContentType: file.type
        }).then(res => {
          if (res.statusCode === 200) {
            console.log(111, res);
            resolve({
              urls: {
                default: "//g.lxx-inc.com/afos-admin-upload/" + filename,
              },

              alt: filename,
              name: file.name,
            })
          } else {
            reject('文件上传失败：' + file.name)
          }
        })


      }));
  }


}

// ...

export default function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    // Configure the URL to the upload script in your back-end here!
    return new MyUploadAdapter(loader);
  };
}