const key = 'LXX_ADMIN_CURRENT_LOGIN_USER_INFO';
const setCurrentUser = (user) => {
  localStorage.setItem(key, JSON.stringify(user));
}

const getCurrentUser = () => {
  const str = localStorage.getItem(key);
  let result = null;
  try {
    result = JSON.parse(str);
  } catch (error) {
    console.error(error);
  }
  return result;
}

const userUtil = {
  set: setCurrentUser,
  get: getCurrentUser,
}

export default userUtil;