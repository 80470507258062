const api = {
  captcha: "/captcha/img",

  auth: {
    captcha: '/auth/captcha',
    login: '/auth/login',
    register: '/auth/register',
  },
  users: {
    me: '/users/me',
  },
  user: {
    create: '/user/create',
    update: '/user/update',
    detail: '/user/detail',
    list: '/user/list',
    delete: '/user/delete',
  },
  article: {
    create: '/article/create',
    update: '/article/update',
    detail: '/article/detail',
    list: '/article/list',
    delete: '/article/delete',
  },
  articleCollection: {
    create: '/articleCollection/create',
    update: '/articleCollection/update',
    detail: '/articleCollection/detail',
    list: '/articleCollection/list',
    delete: '/articleCollection/delete',
  },
  banner: {
    create: '/banner/create',
    update: '/banner/update',
    detail: '/banner/detail',
    list: '/banner/list',
    delete: '/banner/delete',
  },
  wxGroup: {
    create: '/wxGroup/create',
    update: '/wxGroup/update',
    detail: '/wxGroup/detail',
    list: '/wxGroup/list',
    delete: '/wxGroup/delete',
  },
  category: {
    create: '/category/create',
    update: '/category/update',
    detail: '/category/detail',
    list: '/category/list',
    delete: '/category/delete',
  },
  survey: {
    create: '/survey/create',
    update: '/survey/update',
    detail: '/survey/detail',
    list: '/survey/list',
    delete: '/survey/delete',
  },
  shareViewLog: {
    create: '/shareViewLog/create',
    update: '/shareViewLog/update',
    detail: '/shareViewLog/detail',
    list: '/shareViewLog/list',
    delete: '/shareViewLog/delete',
  }
}
export default api;