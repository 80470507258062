import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import 'antd/dist/antd.css';
import '@ant-design/pro-layout/dist/layout.css';
import './index.scss';
import App from './App';
// import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: "https://4f5c55d121c044deb7c7f9c238f6f33e@o239404.ingest.sentry.io/5897171",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


ReactDOM.render(<App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
