import COS from 'cos-js-sdk-v5';

const prefixFolder = 'afos-admin-upload/';

const cosConfig = {
  Bucket: 'afs-node-1252354494',
  Region: 'ap-shanghai',
  StorageClass: 'STANDARD',
  SliceSize: 1024 * 1024 * 5,
}

const cosClient = new COS({
  SecretId: 'AKIDIVdhW4MOBj00jCON5YkfJgndCUWHkwsW',
  SecretKey: 'QbWBZgW6OP9dpkOkqeBRRA2dy2oJXVBa',
});


const cosUploader = {
  cosClient,
  uploadFile: ({ key, body, onError, ...rest }) => cosClient.uploadFile({
    ...cosConfig,
    Key: prefixFolder + key,
    Body: body,
    ...rest
  }, onError),
}
export default cosUploader;