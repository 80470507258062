import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import {
  Upload,
  message
} from 'antd';
import BraftEditor from 'braft-editor';
import { ContentUtils } from 'braft-utils'
import { FileImageOutlined } from '@ant-design/icons';
import 'braft-editor/dist/index.css';
import cosUploader from '../../utils/cosUploader';


const controls = [
  'undo', 'redo', 'separator',
  'font-size', 'line-height', 'letter-spacing', 'separator',
  'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
  'superscript', 'subscript', 'remove-styles', 'emoji', 'separator', 'text-indent', 'text-align', 'separator',
  'headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
  'link', 'separator', 'hr', 'separator',
  // 'media', //富媒体按钮不展示 用自动应以的
  'separator',
  'clear'
];

const RichTextEditor = ({ editorState, onChange }) => {
  const uploadHandler = async (param) => {
    if (!param || !param.file) {
      return false
    }
    const { file: fileItem } = param;
    const filename = dayjs().format('YYYY-MM-DD_HH-mm-ss') + '_' + fileItem.name;
    const res = await cosUploader.uploadFile({
      key: filename,
      body: fileItem,
      ContentType: fileItem.type
    })
    if (res && res.Location) {
      onChange(ContentUtils.insertMedias(editorState, [{
        // todo 处理一把视频
        type: 'IMAGE',
        url: "//g.lxx-inc.com/afos-admin-upload/" + filename,
      }]))
    } else {
      message.error('上传失败');
    }
  }

  const extendControls = [
    {
      key: 'upload-to-cos',
      type: 'component',
      component: (
        <Upload
          accept="image/*"
          showUploadList={false}
          customRequest={uploadHandler}
        >
          {/* 这里的按钮最好加上type="button"，以避免在表单容器中触发表单提交，用Antd的Button组件则无需如此 */}
          <button type="button" className="control-item button upload-button" data-title="插入图片">
            <FileImageOutlined />插入图片
          </button>
        </Upload>
      )
    }
  ]


  return (
    <BraftEditor
      style={{ border: '1px solid #d9d9d9' }}
      value={editorState}
      onChange={onChange}
      extendControls={extendControls}
      controls={controls}
    />
  )
};

export default RichTextEditor;
