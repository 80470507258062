import React from 'react';
import { Layout, Menu } from 'antd';
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Link,
} from "react-router-dom";
import './index.scss';
import Logo from '../logo/index';

const { Sider } = Layout;

class SideBar extends React.Component {
  render() {
    return (
      <Sider
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}
        trigger={null}
        collapsible
        collapsed={this.props.collapsed}
      >
        <Logo></Logo>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['dashboard']}>
          <Menu.Item key="dashboard" icon={<UserOutlined />}>
            <Link to="/dashboard">控制中心</Link>
          </Menu.Item>
          <Menu.Item key="article" icon={<VideoCameraOutlined />}>
            <Link to="/article">文章管理</Link>
          </Menu.Item>
          <Menu.Item key="banner" icon={<VideoCameraOutlined />}>
            <Link to="/banner">Banner管理</Link>
          </Menu.Item>

          <Menu.Item key="category" icon={<VideoCameraOutlined />}>
            <Link to="/category">文章分类管理</Link>
          </Menu.Item>
          <Menu.Item key="articleCollection" icon={<VideoCameraOutlined />}>
            <Link to="/articleCollection">文章合集管理</Link>
          </Menu.Item>

          <Menu.Item key="wxGroup" icon={<VideoCameraOutlined />}>
            <Link to="/wxGroup">微信群管理</Link>
          </Menu.Item>
          <Menu.Item key="user" icon={<UploadOutlined />}>
            <Link to="/user">用户管理</Link>
          </Menu.Item>
          {
            //   <Menu.Item key="survey" icon={<VideoCameraOutlined />}>
            //   <Link to="/survey">问卷管理</Link>
            // </Menu.Item>
          }
        </Menu>
      </Sider>
    );
  }
}

export default SideBar;