import React, { useState } from 'react';
import { Upload, message, Button, Progress } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { LoadingOutlined, CloudUploadOutlined } from '@ant-design/icons';
import cosUploader from '../../utils/cosUploader';

const Uploader = ({ onChange, fileList, ...props }) => {

  const [progressData, setProgressData] = useState(null);

  const handleChange = async (info) => {
    const list = await Promise.all((info.fileList || []).map(async (fileItem) => {
      if (fileItem.url) return fileItem;
      const filename = dayjs().format('YYYY-MM-DD_HH-mm-ss') + '_' + fileItem.name;
      const res = await cosUploader.uploadFile({
        key: filename,
        body: fileItem.originFileObj,
        ContentType: fileItem.type,
        onProgress: setProgressData,
      })
      if (res.statusCode === 200) {
        return {
          url: "//g.lxx-inc.com/afos-admin-upload/" + filename,
          key: filename,
          name: fileItem.name,
        }
      } else {
        message.error('文件上传失败：' + fileItem.name)
        return null;
      }
    }))
    onChange(list);
  };

  return (
    <Upload
      listType={props?.listType || "picture"}
      fileList={fileList}
      onChange={handleChange}
      percent={progressData?.percent}
      progress={{
        strokeColor: {
          '0%': '#108ee9',
          '100%': '#87d068',
        },
        strokeWidth: 3,
        format: percent => `${parseFloat(percent.toFixed(2))}%`,
      }}
      {...props}
    >
      <div style={{ cursor: 'pointer' }}>
        <Button>
          <CloudUploadOutlined />点击上传
        </Button>
        <div>
          {
            progressData &&
            <Progress percent={(progressData?.percent || 0) * 100} />
          }
        </div>
      </div>
    </Upload>
  );
}

export default Uploader;
