import React, { useState, useEffect, useRef } from 'react';
import { PageHeader, Menu, Dropdown, Button, Tag, Typography, Row, Table } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import queryString from "query-string";
import _ from 'lodash';
import dayjs from 'dayjs';
import API from '../../../utils/api';
import request from "../../../utils/request";
import {
  Form,
  Select,
  InputNumber,
  Switch,
  Input,
  message
} from 'antd';

const Content = ({ children, extraContent }) => (
  <Row>
    <div style={{ flex: 1 }}>{children}</div>
    <div className="image">{extraContent}</div>
  </Row>
);

const ArticleDetail = () => {
  const [id, setId] = useState(null);
  const [htmlContent, setHtmlContent] = useState('');
  const [shareViewLogList, setShareViewLogList] = useState(null);

  const [articleDetail, setArticleDetail] = useState({
    coverList: [],
    videoList: [],
  });

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    if (parsed?.id) {

      setId(parsed.id);

      const getDetail = async () => {
        const res = await request.get(API.article.detail, { params: { id: parsed.id } });
        if (res?.message) {
          return message.error(res?.message);
        }
        setArticleDetail(res);
        setHtmlContent(_.unescape(res.content || ''));
      }

      const getShareViewLogByArticleId = async () => {
        const res = await request.get(API.shareViewLog.list, { params: { article: parsed.id } });
        if (res?.message) {
          return message.error(res?.message);
        }
        setShareViewLogList(res?.results);
      }

      getDetail();
      getShareViewLogByArticleId();
    }
  }, []);

  if (!articleDetail) {
    return null;
  }

  return (
    <Content>
      <h1>{articleDetail?.title}</h1>
      <div>{dayjs(articleDetail?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
      <div className="content" dangerouslySetInnerHTML={{ __html: htmlContent }} />

      {
        shareViewLogList?.length > 0 &&
        <div style={{ marginTop: 20 }}>
          <h2>分享回流查看记录</h2>
          <Table
            pagination={{
              // current,
              // pageSize,
              // total,
              // onChange: setCurrent,
              // onShowSizeChange: setPageSize
            }}
            dataSource={shareViewLogList || []}
          >
            <Table.Column title="访问时间" dataIndex="createdAt" render={v => dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />
            <Table.Column title="访问者" dataIndex={['viewUser', 'name']} />
            <Table.Column title="分享时间" dataIndex="shareTime" render={v => dayjs(+v).format('YYYY-MM-DD HH:mm:ss')} />
            <Table.Column title="分享者" dataIndex={['shareUser', 'name']} />
            <Table.Column title="分享到" dataIndex="shareType" render={v => {
              const shareTypeMap = {
                APP_MESSAGE: '微信消息',
                TIMELINE: '朋友圈'
              }
              return shareTypeMap[v]
            }} />
            <Table.Column title="从文章合集分享" dataIndex={['articleCollection', 'title']} />
          </Table>
        </div>
      }

    </Content>
  )
}

export default ArticleDetail;