import React from 'react';
import './index.scss';

const Logo = ({ style }) => {
  return (
    <div className="logo" style={style || {}}>
      <span>
      老年人手机课
      </span>
    </div>
  )
}

export default Logo;