import { Form, Input, Button, Checkbox, PageHeader, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import './index.scss';
import API from '../../utils/api';
import request from "../../utils/request";
import Cookies from 'js-cookie';
import userUtil from '../../utils/user';
import queryString from 'query-string';

const Login = () => {
  const history = useHistory();

  const onFinish = async (values) => {
    const res = await request.post(API.auth.login, values);
    if (res?.message) {
      return message.error(res?.message);
    }

    Cookies.set('access_token', res?.tokens?.access?.token)
    userUtil.set(res?.user);
    message.success('登录成功');
    const parsed = queryString.parse(window.location.search);
    if (parsed?.callbackUrl) {
      window.location.href = decodeURIComponent(parsed.callbackUrl);
    } else {
      window.location.href = '/dashboard';
    }
  };

  return (
    <div className="login-page">
      <PageHeader
        className="site-page-header"
        // onBack={() => null}
        title="登录"
        subTitle="即将老年人手机课管理中心"
      />

      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: '邮箱不合法!',
            },
            {
              required: true,
              message: '请输入邮箱!',
            },
          ]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="电子邮箱" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: '请输入密码！',
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="密码"
          />
        </Form.Item>
        <Form.Item>
          <a className="login-form-forgot" href="">
            忘记密码
          </a>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            登录
          </Button>
          {
            // 或者
            // <Button type="link" onClick={() => history.push('/register')}>立即注册!</Button>
          }
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;