import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Select, Button, PageHeader, message } from 'antd';
import './index.scss';
import CaptchaInput from '../../components/captchaInput/index';
import API from '../../utils/api';
import request from "../../utils/request";
import { Cookies } from 'js-cookie';
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const Register = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const res = await request.post(API.auth.register, {
      phone: values.phone,
      email: values.email,
      name: values.name,
      password: values.password,
      captcha: values.captcha
    });
    if (res.message) {
      return message.error(res.message);
    }
    message.success('注册成功，请前往登录页面登录');
    setTimeout(() => {
      history.push('/login');
    }, 3000)

  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );

  return (
    <div className="register-page">
      <PageHeader
        className="site-page-header"
        // onBack={() => null}
        title="注册账号"
        subTitle=""
      />

      <Form
        {...formItemLayout}
        form={form}
        name="register"
        className="register-form"
        onFinish={onFinish}
        initialValues={{
          prefix: '86',
          gender: 'male'
        }}
        scrollToFirstError
      >
        <Form.Item
          name="name"
          label="用户名"
          rules={[
            {
              required: true,
              message: '请输入昵称!',
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="电子邮箱"
          rules={[
            {
              type: 'email',
              message: '邮箱不合法!',
            },
            {
              required: true,
              message: '请输入邮箱!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="password"
          label="密码"
          rules={[
            {
              required: true,
              message: '请输入密码!',
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="确认密码"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: '请确认密码!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error('两次输入的密码不一致，请检查!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="phone"
          label="手机号"
          rules={[
            {
              required: true,
              message: '请输入手机号!',
            },
          ]}
        >
          <Input
            addonBefore={prefixSelector}
            style={{
              width: '100%',
            }}
          />
        </Form.Item>


        <Form.Item label="验证码" name="captcha" rules={[
          {
            required: true,
            message: '请输入验证码!',
          },
        ]}>
          <CaptchaInput onChange={val => console.log(val)} />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" className="register-form-button">
            注册
          </Button>
          已有账号？
          <Button type="link" onClick={() => history.push('/login')}>直接登录</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Register;