import React, { useEffect, useState } from 'react';
import { Layout, message, Avatar } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined
} from '@ant-design/icons';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Article from './pages/article/index';
import ArticleCollection from './pages/articleCollection/index';
import Banner from './pages/banner/index';
import WxGroup from './pages/wxGroup/index';
import Category from './pages/category/index';
import Survey from './pages/survey/index';
import User from './pages/user/index';
import Dashboard from './pages/dashboard/index';
import Login from './pages/login/index';
import Register from './pages/register/index';
import './App.scss';
import SideBar from './components/sideBar/index';
import Logo from './components/logo/index';
import userUtil from './utils/user';

const { Header, Content, Footer } = Layout;

const App = () => {
  const [collapsed, setCollapsed] = useState(false);

  const user = userUtil.get();
  if (!user && !/register|login/.test(window.location.pathname)) {
    window.location.href = '/login'
  }
  const toggle = () => {
    setCollapsed(!collapsed)
  };

  const renderAuthenticatedLayout = () => {

    const CollapsedIcon = collapsed ? MenuUnfoldOutlined : MenuFoldOutlined;

    return (
      <Router>
        <Layout>

          <SideBar collapsed={collapsed} />

          <Layout className="site-layout" style={{ paddingLeft: collapsed ? 80 : 200 }}>

            <Header className="site-layout-background" style={{ padding: 0 }}>
              <div className="header">
                <CollapsedIcon className="trigger" onClick={toggle} />
                <div className="right-content">
                  <div className="user">
                    <Avatar style={{ marginRight: 6 }} icon={<UserOutlined />} />
                    {user.name}
                  </div>
                </div>
              </div>
            </Header>

            <Content className="site-layout-background main-content-warp">
              <Switch>
                <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                <Route path="/register" component={Register} />
                <Route path="/login" component={Login} />
                <Route path="/dashboard" component={() => <Dashboard user={user} />} />
                <Route path="/article" component={Article} />
                <Route path="/articleCollection" component={ArticleCollection} />
                <Route path="/banner" component={Banner} />
                <Route path="/wxGroup" component={WxGroup} />
                <Route path="/category" component={Category} />
                <Route path="/survey" component={Survey} />
                <Route path="/user" component={User} />

              </Switch>
            </Content>

            <Footer>
              <div style={{ textAlign: 'center' }}>CopyRight 老年人手机课 2021</div>
            </Footer>
          </Layout>
        </Layout>
      </Router>
    );
  }

  const renderUnAuthenticatedLayout = () => {
    return (
      <Router>
        <Layout className="site-layout" >
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <Logo style={{ color: 'rgba(0, 0, 0, 0.85)' }} />
          </Header>

          <Content className="site-layout-background main-content-warp">
            <Switch>
              <Route path="/" component={Register} exact />
              <Route path="/register" component={Register} />
              <Route path="/login" component={Login} />
            </Switch>
          </Content>

          <Footer>
            <div style={{ textAlign: 'center' }}>CopyRight 老年人手机课 2021</div>
          </Footer>
        </Layout>
      </Router>
    );
  }


  if (user) {
    return renderAuthenticatedLayout();
  } else {
    return renderUnAuthenticatedLayout();
  }

}

export default App;