import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Form,
  Button,
  Input,
  message,
  Table,
  Space,
  Modal,
  Radio,
  Switch
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import 'braft-editor/dist/index.css';
import Uploader from '../../../components/uploader/index';
import API from '../../../utils/api';
import request from "../../../utils/request";
import queryString from "query-string";
import mongoObjectId from '../../../utils/objectId';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import './index.scss';

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const formItemLayoutWithOutLabel = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
    offset: 6,
  },
};

const questionTypes = {
  BOOLEAN: '判断题',
  SELECT: '单选题',
  MULTIPLE_SELECT: '多选题',
  TEXTAREA: '文本输入',
};


const SurveyEdit = () => {
  const [id, setId] = useState(null);
  const [surveyDetail, setSurveyDetail] = useState({});
  const [showDialog, setShowDialog] = useState(null);
  const history = useHistory();
  const location = useLocation();

  const [form] = Form.useForm();
  const [questionForm] = Form.useForm();

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed?.id) {
      setId(parsed.id);

      const getDetail = async () => {
        const res = await request.get(API.survey.detail, { params: { id: parsed.id } });
        if (res?.message) {
          return message.error(res?.message);
        }
        res.questions = JSON.parse(res.questions || '[]')
        setSurveyDetail(res);
        form.setFieldsValue({ ...res })
      }

      getDetail();
    }
  }, [location, form]);

  const onFinish = async (values) => {
    const surveyObj = {
      title: values.title,
      desc: values.desc,
      cover: surveyDetail.cover,
      questions: JSON.stringify(surveyDetail.questions || []),
    }
    if (id) {
      surveyObj.id = id;
    }

    const apiUrl = id ? API.survey.update : API.survey.create;
    const res = await request.post(apiUrl, surveyObj);

    if (res?.message) {
      return message.error(res?.message);
    }
    message.success(id ? "保存成功" : '创建成功');
    history.push('/survey/list');
  };

  const onEditingQuestionOk = () => {
    const values = questionForm.getFieldsValue();
    values.required = !!values.required;
    if (values.id) {
      setSurveyDetail({
        ...surveyDetail,
        questions: surveyDetail.questions.map(k => {
          if (k.id === values.id) {
            return values;
          }
          return k;
        })
      })
    } else {
      values.id = mongoObjectId();
      setSurveyDetail({
        ...surveyDetail,
        questions: (surveyDetail.questions || []).concat([values])
      })
    }
    closeDialog();
  }

  const openDialog = (item) => {
    console.log(1223, item)
    let itemObj = item || { type: "BOOLEAN", required: false };
    questionForm.setFieldsValue(itemObj)
    setShowDialog(true);
  }
  const closeDialog = () => {
    questionForm.resetFields();
    setShowDialog(false);
  }

  return (
    <Form
      name="validate_other"
      {...formItemLayout}
      onFinish={onFinish}
      form={form}
      initialValues={{}}
    >
      <Form.Item
        {...formItemLayout}
        name="title"
        label="调研问卷名称"
        rules={[
          {
            required: true,
            message: '调研问卷名称不能为空',
          },
        ]}
      >
        <Input placeholder="请输入调研问卷名称" />
      </Form.Item>

      <Form.Item label="封面图">
        <Uploader
          fileList={surveyDetail?.cover ? [surveyDetail.cover] : []}
          onChange={v => setSurveyDetail({
            ...surveyDetail,
            cover: v?.[0]
          })}
        />
      </Form.Item>

      <Form.Item label="描述" name="desc" >
        <Input.TextArea />
      </Form.Item>

      <Form.Item label="问题项" name="questions" >
        <div className="question-wrap">
          <Table dataSource={surveyDetail.questions || []} pagination={{ position: ['none', 'none'] }}>
            <Table.Column title="问题标题" dataIndex="question" key="question" />
            <Table.Column title="问题描述" dataIndex="desc" key="desc" />
            <Table.Column title="问题类型" dataIndex="type" key="type" render={v => <span>{questionTypes[v]}</span>} />
            <Table.Column title="是否必填" dataIndex="required" key="required" render={v => <span>{!!v ? '是' : '否'}</span>} />
            <Table.Column title="操作" dataIndex="operate" key="operate" render={(value, record, index) => {
              return (
                <Space size="middle">
                  <Button type="link" onClick={() => openDialog(record)} >编辑</Button>
                  <Button type="link" >删除</Button>
                </Space>
              )
            }} />
          </Table>
          <div style={{ textAlign: 'right', marginTop: 10 }}>
            <Button size="small" onClick={() => openDialog()} type="link">添加问题项</Button>
          </div>
        </div>

      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Button type="primary" htmlType="submit">
          提交
        </Button>
      </Form.Item>

      <Modal
        title="添加/编辑问题项"
        visible={showDialog}
        width={800}
        onOk={onEditingQuestionOk}
        onCancel={() => closeDialog()}
        onClose={() => closeDialog()}
      >
        <Form {...formItemLayout} form={questionForm}>
          <Form.Item label="问题ID" name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item label="问题题目" name="question" required>
            <Input placeholder="如：你觉得这个提示有用吗？" />
          </Form.Item>
          <Form.Item label="题目描述" name="desc">
            <Input placeholder="问题详细描述，可以不填" />
          </Form.Item>
          <Form.Item label="是否必填" name="required">
            <Switch
              checkedChildren="必填"
              unCheckedChildren="非必填"
              defaultChecked={false}
            />
          </Form.Item>
          <Form.Item label="问题类型" name="type" required>
            <Radio.Group value={questionForm.getFieldValue('type')}>
              <Radio.Button value={"BOOLEAN"}>判断题</Radio.Button>
              <Radio.Button value={"TEXTAREA"}>文本输入</Radio.Button>
              <Radio.Button value={"SELECT"}>选择题</Radio.Button>
              <Radio.Button value={"MULTIPLE_SELECT"}>多选</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            wrapperCol={24}
            shouldUpdate={(prevValues, curValues) => prevValues.type !== curValues.type}>
            {
              () => {
                if (["SELECT", "MULTIPLE_SELECT"].includes(questionForm.getFieldValue('type'))) {
                  return (
                    <Form.List name="options">
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map((field, index) => (
                            <Form.Item
                              {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                              label={index === 0 ? '可选选项' : ''}
                              required={false}
                              key={field.key}
                            >
                              <Form.Item
                                {...field}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: "请输入选项内容或者删除本行",
                                  },
                                ]}
                                noStyle
                              >
                                <Input placeholder="请输入选项内容" style={{ width: '90%' }} />
                              </Form.Item>
                              {fields.length > 1 && (
                                <MinusCircleOutlined
                                  className="dynamic-delete-button"
                                  onClick={() => remove(field.name)}
                                />
                              )}
                            </Form.Item>
                          ))}
                          <Form.Item {...formItemLayoutWithOutLabel}>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              style={{ width: '60%' }}
                              icon={<PlusOutlined />}
                            >
                              添加选项
                            </Button>
                            <Form.ErrorList errors={errors} />
                          </Form.Item>
                        </>
                      )}
                    </Form.List>

                  )
                }
              }
            }

          </Form.Item>


        </Form>
      </Modal>
    </Form >
  );
};

export default SurveyEdit;
