import React from "react";
import List from './list/index';
import Edit from './edit/index';
import Detail from './detail/index';
import { Route } from "react-router-dom";

export default function Category() {
  return (
    <Route
      path="/survey"
      render={({ match: { url } }) => (
        <React.Fragment>
          <Route path={`${url}/`} component={List} exact />
          <Route path={`${url}/list`} component={List} />
          <Route path={`${url}/detail`} component={Detail} />
          <Route path={`${url}/edit`} component={Edit} />
        </React.Fragment>
      )}
    />
  )
}