import React, { useState, useEffect } from 'react';
import { Input, message } from 'antd';
import { SafetyCertificateOutlined } from '@ant-design/icons';
import API from '../../utils/api';
import request from "../../utils/request";

/**
* 获取验证码
*/
const getCaptcha = async () => {
  const res = await request.get(API.auth.captcha) || {};
  if (res.message) {
    return message.error('获取验证码失败,请重试:' + res.message || '');
  }
  return res;

}

const CaptchaInput = ({ value = {}, onChange }) => {
  const [captchaCode, setCaptchaCode] = useState('');
  const [captchaKey, setCaptchaKey] = useState('');
  const [imageData, setImageData] = useState('');

  // 触发改变
  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange({ captchaCode, captchaKey, ...value, ...changedValue });
    }
  };

  useEffect(() => {
    getCaptcha().then((data) => {
      setCaptchaKey(data.key);
      setImageData(data.svg);
      triggerChange({ captchaKey: data.key });
    })
  }, []);
  // 输入框变化
  const onChangeInput = (e) => {
    const code = e.target.value || '';
    if (code) {
      setCaptchaCode(code);
    }
    triggerChange({ captchaCode: code });
  }
  // 时间类型变化
  const onClickImage = () => {
    getCaptcha().then((data) => {
      setCaptchaKey(data.key);
      setImageData(data.svg);
      triggerChange({ captchaKey: data.key });
    })
  };

  const blob = new Blob([imageData], { type: 'image/svg+xml' });
  const imgUrl = URL.createObjectURL(blob);

  return (
    <span>
      <Input.Group compact>
        <Input prefix={<SafetyCertificateOutlined style={{ color: "#319cff" }} />}
          placeholder={'请输入验证码'}
          onChange={onChangeInput}
          style={{ width: '60%', marginRight: 5, padding: '6.5px 11px 6.5px 11px' }} />
        <img
          style={{ width: 'calc(40% - 5px)', height: '35px', }}
          src={imgUrl}
          onClick={onClickImage}
          alt="验证码"
        />
      </Input.Group>
    </span>
  );
};
export default CaptchaInput;